import React from 'react';
import { VideoCourse } from '../../types/site';
import {Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, Modal, Stack, Typography} from '@mui/material';


const Course = (videoCourse: VideoCourse): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Card sx={{width:370}}>
      <CardHeader title={videoCourse.title} subheader={'Released: ' + videoCourse.releaseDate} sx={{height:120}}/>
      <CardMedia component={'img'} height={'175'} src={videoCourse.thumbnail} />
      <CardContent>
        <Stack direction={'row'} justifyContent='center' alignItems='center' spacing={2}>
          {videoCourse.tags.map((tag, i) => {
            return <Chip label={tag} key={i} />;
          })}
        </Stack>
        <Stack direction={'row'} justifyContent='center' alignItems='center' spacing={5}>
          <Button href={videoCourse.url} target={'_blank'} rel={'noopener'}>
            View Course
          </Button>
          <Button onClick={handleOpen}>
            View Description
          </Button>
        </Stack>
      </CardContent>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4
        }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {videoCourse.title}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            {videoCourse.description}
          </Typography>
        </Box>
      </Modal>
    </Card>
  );
};

export default Course;
