import React from 'react';
import {Box, Chip, Container, Stack} from '@mui/material';

const Skills = ():JSX.Element => {

  return(
    <Box>
      <Container>
        <h2>Languages</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'Java'}/>
          <Chip label={'Go'}/>
          <Chip label={'Python'}/>
          <Chip label={'Bash'}/>
          <Chip label={'JavaScript'}/>
          <Chip label={'HTML5'}/>
          <Chip label={'TypeScript'}/>
          <Chip label={'C#'}/>
          <Chip label={'C++'}/>
        </Stack>
        <h2>Cloud Vendors</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'AWS'}/>
          <Chip label={'GCP'}/>
          <Chip label={'Azure'}/>
        </Stack>
        <h2>Containers/Runtimes/OS</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'Kubernetes'}/>
          <Chip label={'Docker'}/>
          <Chip label={'Linux'}/>
          <Chip label={'OSX'}/>
          <Chip label={'Cloud Foundry'}/>
        </Stack>
        <h2>Databases</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'SQL'}/>
          <Chip label={'Oracle'}/>
          <Chip label={'MySQL'}/>
          <Chip label={'Postgresql'}/>
          <Chip label={'Aurora'}/>
          <Chip label={'MongoDB'}/>
          <Chip label={'Cassandra'}/>
          <Chip label={'Gemfire'}/>
        </Stack>
        <h2>Middleware/Other</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'RabbitMQ'}/>
          <Chip label={'SQS/Kinesis'}/>
          <Chip label={'Kafka'}/>
          <Chip label={'Elastic/Open Search'}/>
          <Chip label={'Pulsar'}/>
          <Chip label={'S3/Object Store'}/>
        </Stack>
        <h2>Dev Tools/CICD</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'Git'}/>
          <Chip label={'GitHub Actions'}/>
          <Chip label={'Maven'}/>
          <Chip label={'Gradle'}/>
          <Chip label={'Jenkins'}/>
        </Stack>
        <h2>Certifications</h2>
        <Stack direction={'row'} justifyContent='flex-start' spacing={2}>
          <Chip label={'Certified Spring Professional 4.0'}/>
          <Chip label={'Certified Spring Professional 3.2'}/>
          <Chip label={'A+'}/>
          <Chip label={'Network+'}/>
        </Stack>
      </Container>
    </Box>
  );
};

export default Skills;