import React, {useLayoutEffect, useState} from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import {Degree} from '../types/site';

const degrees:Degree[] =[
  {
    degree: 'Master of Science',
    conferred: '2014',
    focus: 'Information Technology with Emphasis in Software Engineering & Management',
    institution: 'University of Kansas',
    location: 'Lawrence, Kansas',
  },
  {
    degree: 'Bachelor of Science',
    conferred: '2009',
    focus: 'Computer Science',
    institution: 'University of Missouri, Kansas City',
    location: 'Kansas City, Missouri',
  },
  {
    degree: 'Associate of Applied Science',
    conferred: '2004',
    focus: 'Information Technology',
    institution: 'Johnson County Community College',
    location: 'Overland Park, Kansas',
  },
  {
    degree: 'Bachelor of General Studies',
    conferred: '2001',
    focus: 'Microbiology',
    institution: 'University of Kansas',
    location: 'Lawrence, Kansas',
  }
];

const Education = (): JSX.Element => {
  const [results, setResults] = useState<Degree[]>([]);

  useLayoutEffect(() => {
    setResults(degrees);
  }, []);

  return (
    <Container>
      <Box textAlign="center">
        {results?.map((data) => {
          return (
            <Box key={1} sx={{ padding: '5px' }}>
              <Paper elevation={1}>
                <Typography variant={'h5'} component="p">
                  {data.degree} in {data.focus}
                </Typography>
                <p>Conferred: {data.conferred}</p>
                <p>from</p>
                <Typography variant={'h6'} component="p">
                  <p>{data.institution}</p>
                </Typography>
                <p>{data.location}</p>
              </Paper>
            </Box>
          );
        })}
        ;
      </Box>
    </Container>
  );
};

export default Education;
