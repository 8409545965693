import React, { useLayoutEffect, useState } from 'react';
import { VideoCourse } from '../types/site';
import {Box, Container, Grid, Link} from '@mui/material';
import Course from '../components/course/Course';

const videoCourses:VideoCourse[] = [
  {
    title: 'Microservices Foundations',
    description: 'Microservices is a major architectural pattern in the software industry, and having an overview of what this architecture is, and what it is not, critical as a starting point to evaluating this model. This course covers the basic concepts of microservices, to help you determine if this architectural model is the right fit for you and your team. Instructor Frank Moley kicks off the course by briefly discussing how microservices fit into the history of software architecture, and going into some of the other notable patterns that have emerged in recent years. Frank then helps to familiarize you with some core concepts of microservices, including bounded contexts and the API layer. He also goes over some of the more advanced areas of the architecture, as well as the importance of embracing a DevOps culture should you choose to move to microservices.',
    url: 'https://www.linkedin.com/learning/microservices-foundations',
    tags: ['Architecture', 'Microservices'],
    thumbnail: 'thumbnails/ms-fnd.jpeg',
    releaseDate: '2018-02-09'
  },
  {
    title: 'Spring Boot 2.x Essential Training',
    description: 'Learn how to get started with Spring Boot 2.x, a powerful framework that can help you build web applications quickly, using less code. The Java-based programs you build in Spring Boot "just run": they resolve their own dependencies and create containers for running code in any environment. Learn how to use Spring Boot to build your projects in this course with software architect and Spring developer Frank Moley. Frank shows how to initialize, containerize, and run Spring Boot web apps and CLI apps, and use the library of common starter projects to fill business-critical needs: data, messaging, security, and more. Plus, find how to extend Spring Boot by creating your own starters and use the Actuator module\'s HTTP endpoints to monitor and manage your apps.',
    url: 'https://www.linkedin.com/learning/spring-boot-2-0-essential-training-2',
    tags: ['Java', 'Spring Framework'],
    thumbnail: 'thumbnails/sb-esst-2.jpeg',
    releaseDate: '2021-01-08'
  },
  {
    title: 'Learning Spring with Spring Boot',
    description: 'Spring is the hugely popular framework for developing Java applications in the enterprise space. In this course, discover how to leverage Spring Boot, which takes an opinionated view of the Spring platform. Follow Frank Moley as he walks through how to use Spring Boot to quickly and efficiently develop a practical, enterprise-style web application. As Frank explains how to develop the app, he helps to familiarize you with the general Spring framework. Plus, Frank dives into some of the basic projects of Spring that you can use to develop Java-based applications.',
    url: 'https://www.linkedin.com/learning/learning-spring-with-spring-boot-13886371',
    tags: ['Java', 'Spring Framework'],
    thumbnail: 'thumbnails/learn-spring.jpeg',
    releaseDate: '2022-01-14'
  },
  {
    title: 'Microservices: Design Patterns',
    description: 'Design patterns save developers from needing to puzzle out solutions to problems that others in their industry have already encountered and solved. In this course, get up to speed with over 15 different design patterns you can leverage when building and operating microservices, as well as the common problems they can help you remedy. Instructor Frank Moley goes over decomposition, integration, data, and operational patterns, tying each pattern to the problem it solves. Learn about the API gateway pattern that can prevent chaos by providing a buffer between underlying services and client needs, the sidecar pattern that allows you to offload processing of some kind to a separate module, and much more.',
    url: 'https://www.linkedin.com/learning/microservices-design-patterns',
    tags: ['Architecture', 'Microservices', 'Design Patterns'],
    thumbnail: 'thumbnails/ms-dp.jpeg',
    releaseDate: '2020-06-18'
  },
  {
    title: 'Cloud Native Twelve-Factor and Fifteen-Factor Applications',
    description: 'The twelve-factor and fifteen-factor methodology helps you build more effective, software-as-a-service apps. It allows for automation, continuous deployment, easy onboarding, and portability between execution environments. By leveraging these methodologies, you can also achieve straightforward deployment on numerous cloud platforms in any language, as well as high scalability without needing to change your tooling, architecture, or team. In this course, join instructor Frank Moley as he walks through the fifteen most important factors for software developers looking to build agile, scalable, and resilient web apps. Along the way, Frank explains exactly how each factor applies to cloud-native development, sharing key pointers, pro tips, and practical strategies for making a legacy application twelve- or fifteen-factor compatible.',
    url: 'https://www.linkedin.com/learning/cloud-native-twelve-factor-and-fifteen-factor-applications',
    tags: ['Architecture', 'Microservices', 'Design Patterns'],
    thumbnail: 'thumbnails/15fact.jpeg',
    releaseDate: '2023-02-24'
  },

  {
    title: 'Spring: Framework in Depth 2.x',
    description: 'Spring is an application framework and inversion-of-control (IOC) container for the Java platform. The framework\'s core features can be used by any Java application and are ideal for enterprise and internet-based app development. Get a comprehensive overview of Spring in this intermediate-level course with software architect Frank Moley. Frank develops applications and web services with Spring, and shares what he knows about configuring the ApplicationContext (the interface for accessing components, loading files, publishing events, and more), as well as the beans (objects within the Spring IOC container). He demonstrates a modern Java configuration workflow and explores the Spring lifecycle in depth, so you can extend the framework and better troubleshoot any issues you have with your applications. Plus, learn how to use aspect-oriented programming to add behaviors to your apps in a reusable way.',
    url: 'https://www.linkedin.com/learning/spring-framework-in-depth-2',
    tags: ['Java', 'Spring Framework'],
    thumbnail: 'thumbnails/sp-depth-2.jpeg',
    releaseDate: '2020-04-02'
  },
  {
    title: 'Build a Microservice with Go',
    description: 'Go has risen as a powerful language selection for programs that rely on concurrency and speed, making it a great option for the development of GRPC- or REST-based web services. Because of its popularity, patterns have arisen that make building web services using Go much easier. These patterns include the two most common abstractions: HTTP request response handling and data access. In this course, software architect and developer Frank Moley guides you through the process of building microservices with Go. Get started in Go by preparing and setting up your environment, project, and clients. Go through the full process of building a service, including multiple challenges and solutions to check your understanding. Plus, learn how to use GORM to read and write data to a database and how to use the Echo middleware framework.',
    url: 'https://www.linkedin.com/learning/build-a-microservice-with-go',
    tags: ['Go', 'Microservices'],
    thumbnail: 'thumbnails/go-micro.jpeg',
    releaseDate: '2023-07-21'
  },
  {
    title: 'Treating Go as an Object-Oriented Language',
    description: 'Did you know that treating Go like an object-oriented programming language can allow you to create more functional and more powerful behavior? Join instructor Frank P Moley III in this hands-on, interactive course to practice advancing your skills in Go, the popular, statically typed programming language. This course includes Code Challenges powered by CoderPad. Code Challenges are interactive coding exercises with real-time feedback, so you can get hands-on coding practice to advance your coding skills. Frank helps you boost your skills as a Go programmer with specific, coding challenges that treat Go like an object-oriented language. Practice creating and working with structs, synthesizing classes, and leveraging encapsulation, composition, and polymorphism, building object-oriented behavior as you go.',
    url: 'https://www.linkedin.com/learning/treating-go-as-an-object-oriented-language',
    tags: ['Go', 'OOP'],
    thumbnail: 'thumbnails/go-oop.jpeg',
    releaseDate: '2023-09-13'
  },
  {
    title: 'Spring: Spring Security',
    description: 'Developers sometimes struggle to see their apps as attackers do. They don\'t automatically recognize the vulnerabilities. That is where frameworks like Spring Security come in. Spring Security offers built-in authentication and authorization features for securing your apps and services, and easy ways to extend the framework to maximize its value. Join security architect Frank Moley, as he shows how to secure your Java projects with Spring Security, LDAP, Active Directory, and WebFlux. Along the way, learn how to use Spring Security to set up your own OAuth 2.0 servers to secure your apps and network services.',
    url: 'https://www.linkedin.com/learning/spring-spring-security-15832928',
    tags: ['Java', 'Spring Framework', 'Security'],
    thumbnail: 'thumbnails/sp-sec.jpeg',
    releaseDate: '2022-04-06'
  },
  {
    title: 'Spring Cloud',
    description: 'Spring Cloud provides tools for developers to quickly build cloud native apps. Using Spring Boot and Spring Cloud, developers can stand up microservices, consume services, perform load balancing, and more. Plus, Spring Cloud solutions are built on the 12-factor app methodology, so they\'ll work well in any distributed environment. Join software and security architect Frank Moley, as he shows how to use Spring Cloud to build cloud native apps that scale beautifully in both traditional data centers and private or public cloud infrastructures. Learn how to set up external configurations, as well as discover and consume services. Frank concentrates on the Spring Cloud Netflix patterns, including Eureka.',
    url: 'https://www.linkedin.com/learning/spring-cloud',
    tags: ['Java', 'Spring Framework', 'Cloud Native'],
    thumbnail: 'thumbnails/spring-cloud.jpeg',
    releaseDate: '2022-10-19'
  },
  {
    title: 'Spring Boot with React',
    description: 'In many use cases, it just makes sense to build React front ends with Spring Boot. Software developer and architect Frank P Moley III guides you through how and why you can accomplish this. Learn how to set up Spring Boot, add React, and set up the build. Explore the architecture behind building web applications, then get walk-throughs of building your first data repositories, building scaffolding for a service to wrap your data access, and building a business service abstraction layer. Go over rich REST APIs the front end can consume, as well as how to start the UI, build a component, load live data, and package it all up. With each chapter in the course, work your way through practical challenges that test your understanding of what has been presented.',
    url: 'https://www.linkedin.com/learning/spring-boot-with-react',
    tags: ['Java', 'Spring Framework', 'React'],
    thumbnail: 'thumbnails/spring-react.jpeg',
    releaseDate: '2023-04-18'
  },
  {
    title: 'Spring Boot: Test Driven Development',
    description: 'Writing code that can easily be tested—early and often—can not only help you achieve higher quality code, but can do so with repeatable patterns that get executed in your build and deployment processes. Practicing test-driven development can help you speed up your software release cycles, and ensure that you end up with a high-quality product. In this course, Frank Moley shows you how to write Spring-based Java applications that follow a test-driven development pattern to help achieve higher-quality code quickly. He gives you an overview of general test-driven development practices in Spring, looks at unit testing in a Spring application, uses mock objects to unit test, integrates testing with Spring, and more. Frank provides challenges and solutions throughout the course, so you can practice what you’ve learned along the way.',
    url: 'https://www.linkedin.com/learning/spring-boot-test-driven-development',
    tags: ['Java', 'Spring Framework', 'Testing'],
    thumbnail: 'thumbnails/spring-test.jpeg',
    releaseDate: '2023-05-11'
  },
  {
    title: 'Extending Kubernetes with Operator Pattern',
    description: 'Kubernetes is a powerful and popular platform, but managing Kubernetes can be difficult, and performing routine operations and repeated tasks can be tedious and error prone. The Kubernetes operator pattern allows an SRE, developer, or operator to build complex provisioning and management tasks directly into the Kubernetes API, allowing you to build repeatable and efficient operation routines. This course with Frank P Moley III explores what it takes to build an operator and utilize it to make the management of K8s more efficient. If you’re looking for ways to save time on routine and repeated actions so you can spend more time building your platform, join Frank as he shows you how to implement your own operator.',
    url: 'https://www.linkedin.com/learning/extending-kubernetes-with-operator-patterns',
    tags: ['Cloud Native', 'Kubernetes'],
    thumbnail: 'thumbnails/k8s-op.jpeg',
    releaseDate: '2022-03-07'
  },
  {
    title: 'Quarkus Essential Training',
    description: 'If you’re looking to ramp up your skill set in Java, you may want to check out Quarkus, the easy-to-use, full-stack Java framework that lets you deploy high-performance applications at scale using containerization platforms like Kubernetes. In this course, instructor Frank P Moley III gives you an overview of the essentials of Quarkus, how it works, and how to implement basic configuration and injection strategies. Discover the three most popular ways to use Quarkus for building a microservices-based architecture: JAX-RS, GraphQL, and Reactive. Along the way, you’ll learn the fundamentals of running a Quarkus project from start to finish, including how to manage data access with Panache.',
    url: 'https://www.linkedin.com/learning/quarkus-essential-training',
    tags: ['Java', 'Quarkus'],
    thumbnail: 'thumbnails/quar-est.jpeg',
    releaseDate: '2022-12-08'
  },
  {
    title: 'Spring with GraphQL',
    description: 'If you’re a back-end engineer or full-stack developer, it’s time to get up to speed with GraphQL, the extremely popular and powerful query language that tells you everything you need to know about your APIs. The problem is, for Java-based, back-end operations, there’s often a gap between traditional frameworks and the data access technologies of most engineers. In this course, instructor Frank Moley shows you how to use JavaScript in Spring Boot to expose your GraphQL APIs so you can work more strategically than ever before. Learn to build, maintain, and evolve robust GraphQL APIs with the full power of the Spring Framework, exploring the basics as well as more advanced topics like mutations, batching, telemetry, error handling, extending an API, and more.',
    url: 'https://www.linkedin.com/learning/spring-with-graphql',
    tags: ['Java', 'Spring Framework', 'GraphQL'],
    thumbnail: 'thumbnails/sp-gql.jpeg',
    releaseDate: '2022-07-18'
  },
  {
    title: 'Microservices: Asynchronous Messaging',
    description: 'Discover how to get work done more efficiently with asynchronous messaging, an alternative way of handling communication in a microservices architecture. In this course, Frank Moley shows software developers charged with building large applications how to go beyond RESTful API calls over HTTP and leverage asynchronous messaging when architecting and building microservices. Frank digs into the gains and tradeoffs you must accept when using asynchronous patterns. He also discusses interservice communication patterns, event-driven microservices patterns, using asynchronous messaging to solve for data migration when moving to microservices architectures, and more.',
    url: 'https://www.linkedin.com/learning/microservices-asynchronous-messaging',
    tags: ['Microservices', 'Architecture'],
    thumbnail: 'thumbnails/ms-async.jpeg',
    releaseDate: '2021-10-29'
  },
  {
    title: 'Programming Foundations: Secure Coding',
    description: 'Learn how to incorporate security into the software development life cycle. Move security into your design and build phases by identifying common insecure code issues and embracing the mindset of a security professional. In this course, security architect Frank Moley provides a basic understanding of secure coding practices. Learn how to understand your attackers and risks and mitigate issues at critical junctures in your code, including thick app, client, and server interactions. Plus, explore how to prevent unauthorized access and data leaks with authentication and cryptography. Frank closes with an overview of security in each phase of the software development life cycle, and next steps for strengthening the security posture of your applications.',
    url: 'https://www.linkedin.com/learning/programming-foundations-secure-coding',
    tags: ['Security', 'General Development'],
    thumbnail: 'thumbnails/pf-sec.jpeg',
    releaseDate: '2018-12-12'
  },
  {
    title: 'Cloud Native Projects: AWS Serverless',
    description: 'Developers need to understand how to use serverless functions in cloud environments. In this course, instructor Frank P Moley III explains how to use AWS Lambda as a serverless framework and why developers should understand how to use this tool. Frank introduces you to AWS Lambda and how to interact with Lambda in the AWS console. He talks about how and why serverless exists and operates as a technology selection, including its benefits, downfalls, and use cases. Frank covers how to use Lambda functions with Python, Java, and Go.',
    url: 'https://www.linkedin.com/learning/cloud-native-projects-aws-serverless',
    tags: ['Cloud Native', 'Go', 'Java', 'Python'],
    thumbnail: 'thumbnails/cnp-aws-sl.jpeg',
    releaseDate: '2021-05-26'
  },
  {
    title: 'Cloud Native Projects: GCP Serverless',
    description: 'Every cloud developer should understand the use of serverless functions in cloud environments. In this course, senior software engineer Frank Moley shows you how to understand and implement serverless cloud functions in Google Cloud Platform (GCP). Frank introduces GCP Cloud Functions, as well as the benefits, negatives, and risks of a serverless system. Then he walks you through the steps and strategies you need to implement Python, Java, and Go in GCP serverless, including practical challenges and solutions for each.',
    url: 'https://www.linkedin.com/learning/cloud-native-projects-gcp-serverless',
    tags: ['Cloud Native', 'Go', 'Java', 'Python'],
    thumbnail: 'thumbnails/cnp-gcp-sl.jpeg',
    releaseDate: '2021-08-03'
  },
  {
    title: 'Cloud Native Projects: Azure Serverless',
    description: 'Every cloud developer should understand the use of serverless functions in cloud environments. In this course, senior software engineer Frank Moley shows you how to understand and implement serverless cloud functions in Google Cloud Platform (GCP). Frank introduces GCP Cloud Functions, as well as the benefits, negatives, and risks of a serverless system. Then he walks you through the steps and strategies you need to implement Python, Java, and Go in GCP serverless, including practical challenges and solutions for each.',
    url: 'https://www.linkedin.com/learning/cloud-native-projects-azure-serverless',
    tags: ['Cloud Native', 'Java', 'Python'],
    thumbnail: 'thumbnails/cnp-azu-sl.jpeg',
    releaseDate: '2021-06-18'
  },
  {
    title: 'Learning Quarkus',
    description: 'Running Java applications, especially in a containerized cloud-native environment, can be a resource-heavy and expensive endeavor, and the prospect of a framework with a smaller footprint with all the power of Java is very appealing. Enter Quarkus, a Kubernetes-based framework that delivers the power of the Java development language and ecosystem with fewer resource needs. In this course, Frank Moley gives an introduction to Quarkus, starting with the basics and then illustrating the framework through a series of challenges and solutions. By the end of the course, it should be clear why Quarkus is quickly becoming one of the most exciting frameworks for building Java-based microservices and cloud-native components.',
    url: 'https://www.linkedin.com/learning/learning-quarkus',
    tags: ['Java', 'Quarkus'],
    thumbnail: 'thumbnails/learn-quarkus.jpeg',
    releaseDate: '2021-03-10'
  },
  {
    title: 'Learn Java Cryptography',
    description: 'Security is paramount for any application. In Java, cryptography is key to the secure storage and transmission of data: to and from resources, users, and APIs. This course teaches the basics of Java cryptography using the Java Development Kit (JDK) crypto libraries: Java Cryptography Architecture (JCA) and Java Cryptography Extensions (JCE). Learn basic cryptography concepts and terms, including symmetric and asymmetric encryption, hashing, and digital signatures. Then find out how to use the cryptographic services or "engine classes" in JCA and JCE, such as Cipher, KeyGenerator, MessageDigest, and Signature, to enforce secure messaging and data storage. Plus, discover how to build a Java keystore to manage your repository of keys and certificates. Instructor Frank Moley uses his 16+ years of experience as a software developer and security architect to guide you through this complex topic.',
    url: 'https://www.linkedin.com/learning/learn-java-cryptography',
    tags: ['Java', 'Cryptography'],
    thumbnail: 'thumbnails/java-crypt.jpeg',
    releaseDate: '2018-07-19'
  },
  {
    title: 'Secure Coding in Java',
    description: 'The enduring popularity of Java makes it a target for bad actors. And its prevalence in internal enterprise applications—which are vulnerable to attacks from employees with insider knowledge—renders secure coding practices even more critical. In this course, instructor Frank Moley helps advanced Java developers kick-start their secure coding journey, providing a high-level look at common attacks against Java applications, as well as how to prevent and defend against those threats. Frank discusses how to prevent SQL injection attacks, identify places where sensitive data is stored and avoid exposing it to the outside world, write code that protects the integrity of the system, and more. Along the way, he provides challenges that allow you to put your new skills to the test.',
    url: 'https://www.linkedin.com/learning/secure-coding-in-java',
    tags: ['Java', 'Security'],
    thumbnail: 'thumbnails/java-sec.jpeg',
    releaseDate: '2020-11-03'
  },
  {
    title: 'Spring: Spring Cloud 2',
    description: 'Spring Cloud provides tools for developers to quickly build cloud native apps. Using Spring Boot and Spring Cloud, developers can stand up microservices, consume services, perform load balancing, and more. Plus, Spring Cloud solutions are built on the 12-factor app methodology, so they\'ll work well in any distributed environment. Join software and security architect Frank Moley, as he shows how to use Spring Cloud to build cloud native apps that scale beautifully in both traditional data centers and private or public cloud infrastructures. Learn how to set up microservices and external configurations, as well as how to discover and consume services. Frank concentrates on the Spring Cloud Netflix patterns, including Eureka.',
    url: 'https://www.linkedin.com/learning/spring-spring-cloud-2',
    tags: ['Java', 'Spring Framework', 'Cloud Native'],
    thumbnail: 'thumbnails/sp-cloud-2.jpeg',
    releaseDate: '2020-01-14'
  },
  {
    title: 'Learning JDBC',
    description: 'Whether developers want to build mobile device apps for Android or web-based or desktop-based applications with the core Java SDK from Oracle, they must contend with the fact that many dynamic applications need to integrate data from a relational database. In this course, Frank Moley helps you get up to speed with the Java Database Connectivity (JDBC) API, showing how to use it to read and manage data from relational databases such as Postgres, Oracle Database, MySQL, and SQL Server in applications programmed with Java. Frank begins by going over key JDBC terminology, the basics of configuring a PostgreSQL database, and how to create the course project. He then provides detailed instructions on how to select and update data, work with transactions, handle exceptions, and more.',
    url: 'https://www.linkedin.com/learning/learning-jdbc',
    tags: ['Java', 'Database'],
    thumbnail: 'thumbnails/jdbc.jpeg',
    releaseDate: '2021-10-08'
  },
  {
    title: 'Java Microservices with GraalVM',
    description: 'Traditional JVMs often fail to deliver the speed and efficiency necessary to run today\'s cloud-based Java applications. GraalVM is a virtual machine that aims to improve performance using strategies such as precompilation and native image creation. Learn how GraalVM can improve your modern Java microservice implementations with examples shown using the Micronaut, Helidon, Quarkus, and Spring frameworks. Instructor Frank Moley first demonstrates how to use GraalVM with existing code artifacts, so you can see the performance benefits on raw code. He then shows how to build a basic microservice in each of the four frameworks—as well as a native image of each service—and reviews the performance and image size metrics of the final microservices. Using these practical examples, you can evaluate which combination will benefit your Java projects the most.',
    url: 'https://www.linkedin.com/learning/java-microservices-with-graalvm',
    tags: ['Java', 'Microservices', 'k8s'],
    thumbnail: 'thumbnails/graal.jpeg',
    releaseDate: '2020-08-14'
  },
  {
    title: 'Java Frameworks: Quarkus vs. Spring',
    description: '"Choosing the best Java framework for your needs can be a challenge. In this course, software developer and architect Frank Moley highlights the benefits and potential issues for two popular Java frameworks: Spring and Quarkus. Frank compares how both frameworks handle dependency injection and data access, with challenges to give you hands-on experience with both. He shows you the MVC pattern with Spring, as well as the JAX-RS pattern for REST-based web services and the Qute templating engine for web pages in Quarkus. Frank walks you through additional integrations, such as logging, observability, messaging, security, and more. Plus, he presents general assessments of these two frameworks, based on real-world work with both, to help you determine which framework is best for your specific needs.',
    url: 'https://www.linkedin.com/learning/java-frameworks-quarkus-vs-spring',
    tags: ['Java', 'Spring Framework', 'Quarkus'],
    thumbnail: 'thumbnails/java-frameworks.jpeg',
    releaseDate: '2022-09-07'
  },
  {
    title: 'Introducing Maven',
    description: 'Apache Maven is a powerful build system used by thousands of Java developers on enterprise, freelance, and open-source projects. But it can do so much more. This course focuses on the basics of using Maven for dependency management, builds, and reporting. Instructor Frank Moley starts with the foundations: the project object model (POM) and POM elements, including properties and dependencies. He then reviews the build life cycle, showing how to leverage the Core and Tools plugins to automate tasks like cleanup and compile. He also shows how to manage dependencies, including creating uber JAR files to package executables with their dependencies. Finally, he covers reporting with Maven sites and popular plugins. By the end of the course, you should be familiar enough with Maven to use it to meet the needs of almost any Java project, from simple components to unique customized builds.',
    url: 'https://www.linkedin.com/learning/introducing-maven',
    tags: ['Java', 'CI/CD'],
    thumbnail: 'thumbnails/maven.jpeg',
    releaseDate: '2019-04-30'
  },
  {
    title: 'Spring: Design Patterns',
    description: 'Design patterns help developers efficiently solve common problems in their code. In this course, learn how to apply Gang of Four design patterns to improve your efficiency in Spring, the application framework and inversion-of-control (IOC) container for the Java platform. Instructor Frank Moley examines the core patterns in play in the Spring framework, as well as how to leverage the unique power of Spring—along with common object-oriented design patterns—to quickly solve problems. Learn how to leverage creational, structural, and operational patterns, as well as other framework patterns, when writing Java applications using the Spring framework.',
    url: 'https://www.linkedin.com/learning/spring-design-patterns',
    tags: ['Java', 'Spring Framework', 'Design Patterns'],
    thumbnail: 'thumbnails/sp-dp.jpeg',
    releaseDate: '2019-03-22'
  },
  {
    title: 'AWS for Developers: S3',
    description: 'Learn how to get started with Amazon Simple Storage Service (S3), the globally distributed, binary object storage service that allows developers to securely store and retrieve data. Instructor Frank Moley demonstrates how to set up an account and walks through S3 basics, such as creating your first storage bucket. He also shows how to set up permissions and configuration options and use the command-line interface (CLI) to script S3 operations. Frank also walks through the AWS software development kits (SDK), which allow you to easily integrate S3 with your Python and Java applications.',
    url: 'https://www.linkedin.com/learning/aws-for-developers-s3',
    tags: ['Cloud Native', 'AWS', 'Java', 'Python'],
    thumbnail: 'thumbnails/aws-s3.jpeg',
    releaseDate: '2020-03-17'
  },
  {
    title: 'Spring: Spring Security (2018)',
    description: 'Developers sometimes struggle to see their apps as attackers do. They don\'t automatically recognize the vulnerabilities. That is where frameworks like Spring Security come in. Spring Security offers built-in authentication and authorization features for securing your apps and services, and easy ways to extend the framework to maximize its value. Join security architect Frank Moley, as he shows how to secure your Java projects with Spring Security, LDAP, Active Directory, and WebFlux. Along the way, learn how to use Spring Security to set up your own OAuth servers to secure your apps and services.',
    url: 'https://www.linkedin.com/learning/spring-spring-security-2018',
    tags: ['Java', 'Spring Framework', 'Security'],
    thumbnail: 'thumbnails/sp-sec-2018.jpeg',
    releaseDate: '2018-05-30'
  },
  {
    title: 'Cloud Native 12-Factor Applications',
    description: 'The twelve-factors app is a methodology that allows for automation, continuous deployment, easy onboarding of new developers, and portability between execution environments. By leveraging this methodology when building modern web-based applications, you can also achieve straightforward deployment on numerous cloud platforms, and high scalability without the need to change tooling, architecture, or how your team works. It can be applied to apps written in any language and using any combination of backing services. In this course, join Frank Moley as he walks through the twelve factors that go into building agile, highly-scalable, and resilient web apps. As he discusses each factor, Frank explains exactly how they apply to cloud-native development, and shares key pointers for turning a legacy application into one that\'s twelve-factor compatible.',
    url: 'https://www.linkedin.com/learning/cloud-native-twelve-factor-applications',
    tags: ['Architecture', 'Microservices', 'Design Patterns'],
    thumbnail: 'thumbnails/12factor.jpeg',
    releaseDate: '2020-06-18'
  },
  {
    title: 'Spring 6 and Spring Boot 3 First Look',
    description: 'Spring is one of the most powerful and popular frameworks for building Java applications. In this course, instructor Frank Moley gives you a look at the latest major revisions, Spring 6 and Sprint Boot 3. He covers the new baseline versions for Spring, as well as several of the various components that were upgraded with Spring Boot. He also details some of the more popular classes that have now been removed, configuration changes in the latest versions, and more. Frank also shows you the most effective methods for upgrading Spring Boot 2.x based applications or Spring 5.x applications to the latest versions.',
    url: 'https://www.linkedin.com/learning/spring-6-and-spring-boot-3-first-look',
    tags: ['Java', 'Spring Framework', 'New Features'],
    thumbnail: 'thumbnails/sp6.jpeg',
    releaseDate: '2022-11-28'
  },
  {
    title: 'Spring 5.x and Spring Boot 2.x New Features',
    description: 'The Spring developers are always listening to feedback and feature requests. They have responded with Spring 5, a version that\'s packed with exciting new enhancements. This course gets you up to speed with the latest and greatest in Spring Framework 5.0 and Spring Boot 2.0, including reactive Spring and Spring Actuator. Instructor Frank Moley covers the new support structure for Java 8 and Java EE 7, and explains which packages, classes, and methods have been removed. Next, learn about the changes to Servlet 3 and 4, data binding for immutable objects, and the spring-web-reactive module for reactive programming. Then explore the integration for Junit Jupiter, parallel test execution, and mock environments. Finally, review the changes in Spring Boot, including third-party library upgrades and Spring Boot Actuator for monitoring the health of production applications.',
    url: 'https://www.linkedin.com/learning/spring-5-0-and-spring-boot-2-0-new-features',
    tags: ['Java', 'Spring Framework', 'New Features'],
    thumbnail: 'thumbnails/sp-new-5-2.jpeg',
    releaseDate: '2018-11-19'
  },
  {
    title: 'Spring Boot 1.X Essential Training',
    description: 'Learn how to get started with Spring Boot, a powerful framework to build web applications quickly, using less code. The Java-based programs you build in Spring Boot "just run": they resolve their own dependencies and create containers for running code in any environment. Learn how to use Spring Boot to build your projects in this course with software architect and Spring developer Frank Moley. Frank shows how to initialize, package, and run Spring Boot web apps and CLI apps, and use the library of common starter projects to fill business-critical needs: data, messaging, security, and more. Plus, find how to extend Spring Boot by creating your own starters and use the Actuator module\'s HTTP endpoints to monitor and manage your apps.',
    url: 'https://www.linkedin.com/learning/spring-boot-1-0-essential-training',
    tags: ['Java', 'Spring Framework'],
    thumbnail: 'thumbnails/sp-esst-1.jpeg',
    releaseDate: '2018-02-27'
  }
];

const Training = (): JSX.Element => {
  const [courses, setCourses] = useState<VideoCourse[]>([]);

  useLayoutEffect(() => {
    setCourses(videoCourses);
  });

  return (
    <Container>
      <h2>LinkedIn Learning Courses</h2>
      <Link href={'https://linkedin-learning.pxf.io/fpmoles'} target={'_blank'} rel={'noopener'}>{'Frank\'s Courses'}</Link>
      <Box>
        <Grid container columns={{ xs: 1, sm: 2, md: 12, lg: 12 }} spacing={{ sm: 1, md: 2 }}>
          {courses.map((course, i) => {
            return (
              <Grid item key={i}>
                <Course
                  title={course.title}
                  description={course.description}
                  url={course.url}
                  tags={course.tags}
                  releaseDate={course.releaseDate}
                  thumbnail={course.thumbnail}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};

export default Training;
