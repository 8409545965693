import React from 'react';
import Home from './pages/Home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LightThemeOptions } from './theme/LightTheme';
import { CssBaseline } from '@mui/material';
import MainLayout from './layout/MainLayout';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Education from './pages/Education';
import ErrorPage from './pages/ErrorPage';
import Experience from './pages/Experience';
import Training from './pages/Training';
import Skills from './pages/Skills';
import Speaking from './pages/Speaking';

function App() {
  const theme = createTheme(LightThemeOptions);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path={'/'} element={<MainLayout />}>
            <Route path={''} element={<Home />} />
            <Route path={'education'} element={<Education />} />
            <Route path={'experience'} element={<Experience />} />
            <Route path={'skills'} element={<Skills />}/>
            <Route path={'training'} element={<Training />} />
            <Route path={'speaking'} element={<Speaking/>}/>
            <Route path={'*'} element={<ErrorPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
