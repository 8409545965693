import React, { useLayoutEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { Company, Role } from '../types/site';
import Job from '../components/job/Job';

const companies:Company[] = [
  {
    name: 'Catch+Release',
    url: 'https://catchandrelease.com',
    dates: 'Dec 2021 - current',
    roles: [
      {
        title: 'Senior Principal Engineer',
        description: 'Responsible for the design, development, platform, and general leadership for the engineering team.',
        functions: [
          'Design an implement v2 version of Catch+Release.',
          'Manage the technology and architect future systems designs.',
          'Develop software using Java, Go, Python, and Javascript.',
          'Act as mentor and leader, actively participating in the company leadership team.',
          'Be an advocate for both users and developers while interacting with internal and external users.'
        ]
      }
    ]
  },
  {
    name: 'M3 Computing, LLC',
    url: 'https://m3computing.com',
    dates: 'Jan 2016 - current',
    roles: [
      {
        title: 'Owner/Principal Software Engineer',
        description: 'As owner and Principal Software Engineer, responsible for all development, educational, and consulting activities.',
        functions: [
          'Develop demand based software for small to enterprise businesses.',
          'Conduct demanding based training sessions.',
          'Provide architectural level consulting and design for cloud native systems including both public and private cloud needs.'
        ]
      }
    ]
  },
  {
    name: 'LinkedIn Learning',
    url: 'https://linkedin.com/learning',
    dates: 'Jan 2016 - current',
    roles: [
      {
        title: 'Content Author',
        description: 'Design, develop, and record courses for the curated learning library',
        functions: [
          'Design and conceptualize courses.',
          'Propose and author courses.',
          'Record new courses and update existing courses.'
        ]
      }
    ]
  },
  {
    name: 'University of Kansas',
    url: 'https://ku.edu',
    dates: 'Jan 2023 - current',
    roles: [
      {
        title: 'Adjunct Professor',
        description: 'Instructor for MSIT program',
        functions: [
          'Create and deliver course content',
          'Instruct and grade students work effort'
        ]
      }
    ]
  },
  {
    name: 'DataStax Inc',
    url: 'https://datastax.com',
    dates: 'Jan 2018 - Dec 2021',
    roles: [
      {
        title: 'Cloud and Platform Architect',
        description: 'Designed, developed, and lead in the operations of DataStax Astra',
        functions: [
          'Provide hands-on technical leadership on utilization of kubernetes and cloud infrastructure in a true multi-cloud and multi-region environment leveraging AWS, GCP, and Azure.',
          'Provided hands-on leadership of management of over 300 kubernetes clusters.',
          'Design, build, and operate on cloud platform.',
          'Work with product, sales, field, marketing and finance to further increase the marketshare of DataStax Astra while focusing on margins, developer needs, and enterprise needs.',
          'Provide hands-on technical leadership on microservices based architectures including the use of ReSTful APIs for globally distributed system.',
          'Create technical design, participate in design reviews, mentor designers, develop software, participate in code reviews, mentor developers.'
        ]
      },
      {
        title: 'Senior Technical Engineering Manager - DataStax Astra',
        description: 'Provided technical (hand-on) leadership to the CSI (Cloud Services and Infrastructure) team in all aspects of design, development, and operations.',
        functions: [
          'Provided hands-on technical leadership for DataStax Astra backend team.',
          'Managed a team of managers, developers, and testers in day to day operations and development.',
          'Worked with product teams to define and implement roadmaps.',
          'Defined architecture, design systems, and design infrastructure for DataStax’s Cloud Platform as well as the entire Astra Backend system.',
          'Oversaw and lead team working on entire suite of tools for DataStax’s Cloud tooling including SSO, IAM, Observability, Billing, Infrastructure Management, and operational needs of these.',
          'Mentoring and personal development of direct reports.'
        ]
      },
      {
        title: 'Technical Engineering Manager - DataStax Cloud Services and Infrastructure',
        description: 'Provided hands-on leadership a team of developers and testers on the design, development, and operations of the DataStax Astra platform and services.',
        functions: [
          'Provided technical leadership for DataStax Astra backend team.',
          'Managed a team of developers and testers in day to day operations and development.',
          'Worked with product teams to define and implement features from the roadmap.',
          'Defined architecture, design systems, and design infrastructure for DataStax’s Cloud Platform as well as Astra Infrastructure systems including cloud native offerings and kubernetes.',
          'Oversaw and lead team working on entire suite of tools for DataStax’s Cloud tooling including SSO, Observability, Infrastructure Management, and operational needs of these.',
          'Mentoring and personal development of direct reports.'
        ]
      },
      {
        title: 'Senior Software Engineer/Tech Lead - DataStax Cloud Services and Infrastructure',
        description: 'Provided technical leadership for the design and development of the DataStax Astra platform and suite of applications.',
        functions: [
          'Founding engineer for DataStax Astra.',
          'Designed Cloud Platform including kubernetes infrastructure, cloud native infrastructure on major cloud providers, dscloud for CI/CD processes and implementations of these.',
          'Built DataStax Cloud offerings backbone of services including SSO, Observability, CI/ CD, Infrastructure Management with Terraform.',
          'Defined core processes for DataStax Cloud offerings including Secure SDLC, Incident Management, Change Control Management, and Onboarding.',
          'Defined and helped build core day one operational footprint for DataStax Astra.'
        ]
      }
    ]
  },
  {
    name: 'Garmin International',
    url: 'https://garmin.com',
    dates: 'Oct 2010 - May 2018',
    roles: [
      {
        title: 'Platform and Security Architect - IT',
        description: 'Responsible for technical design of platform and security engineering for the information technology department.',
        functions: [
          'Responsible for building and defining architecture around private cloud platform and development security.',
          'Designed and helped build Global Private Cloud offering including isolated China Datacenters.',
          'Designed and implemented secure coding practices including static code analysis, cvvs based scoring system, and developer education.',
          'Designed and lead implementation of Garmin’s GDPR footprint across 50+ teams.',
          'Mentored all developers through training and day to day interaction on the use of Spring for microservices development.'
        ]
      },
      {
        title: 'Application Developer 3/2 - IT',
        description: 'Design and develop business based solutions, specifically focusing on the backend Java based development.',
        functions: [
          'Day to Day development for internal systems using Java and building SOAP based services.',
          'Drove and delivered Code Reviews.',
          'Designed applications and systems based on needs of business.',
          'Proposed and built first microservice using Spring and ReST for abstracting customer data.'
        ]
      }
    ]
  },
  {
    name: 'Cerner Corporation',
    url: 'https://cerner.com',
    dates: 'Oct 2002 - Oct 2010',
    roles: [
      {
        title: 'Software Engineer - Discern Isis',
        description: 'Java based webservices developer for the Discern ISIS web offering.',
        functions: [
          'Wrote entire backend of Discern ISIS using Java, Spring, and Apache CXF SOAP based services as a green field solution.',
          'Managed infrastructure for Java Application Deployment.',
          'Designed and build hibernate based abstraction framework with parental relationships for DB2 and Oracle RDBMS.',
          'Implemented first web service based wrapper for Cerner Multum.'
        ]
      },
      {
        title: 'Software Engineer - PharmNet',
        description: 'Developed backend services and desktop application functionality working specifically on Cerner PharmNet Medication Manager and Dose Range Checking.',
        functions: [
          'Designed and wrote software using Visual Basic, C, C++, Java, and CCL.',
          'Managed and enhanced the Dose Range Checker.',
          'Rewrote Pharmacy Sharable of order right server from C to Java.',
          'General application development for Medication Manager.'
        ]
      },
      {
        title: 'Test Engineer - PharmNet',
        description: 'Developer functional, regression, and risk based test scripts for Cerner PharmNet.',
        functions: [
          'Designed and wrote manual software tests for meds integration including Medication Manager.',
          'Troubleshooting and Debugging of issues identified by field or from testing.',
          'Maintained robust regression library per FDA and CHIA processes.',
          'Participated as key member in certification audits for FDA, ISO, and others.'
        ]
      },
      {
        title: 'Technical Solution Analyst - PharmNet',
        description: 'Provided in-house tier 2 and 3 support as well as on-site tier 3 support.',
        functions: [
          'Provided Tier 2 & 3 support for PharmNet Clients over the phone.',
          'Provided Tier 3 support for PharmNet Clients on site.',
          'Provided detailed bug reports and proposed fixes to engineering.',
          'Provided custom CCL solutions to clients.'
        ]
      },
      {
        title: 'Functional Certifiation Analyst - PharmNet',
        description: 'Functional test designer.',
        functions: [
          'Designed Functional Manual Tests.',
          'Executed Functional Manual Tests.',
          'Provided feedback to engineering on identified bugs.',
          'Troubleshooting and debugging of code.'
        ]
      }
    ]
  }
];

const Experience = (): JSX.Element => {
  const [results, setResults] = useState<Company[]>([]);

  useLayoutEffect(() => {
    setResults(companies);
  }, []);

  return (
    <Container>
      <Box>
        {results.map((company, i) => {
          return <Job name={company.name} url={company.url} dates={company.dates} roles={company.roles} key={i} />;
        })}
      </Box>
    </Container>
  );
};

export default Experience;
