import React from 'react';
import {Box, Container, Grid, Link, Stack, Typography} from '@mui/material';
import ResponsiveAppBar from '../components/responsiveAppBar/ResponsiveAppBar';
import { Outlet } from 'react-router-dom';
import LinkedIn from '../assets/linkedin.svg';
import Github from '../assets/github.svg';
import Twitter from '../assets/twitter.svg';
const pages = ['home', 'education', 'experience', 'skills', 'speaking', 'training'];
const MainLayout = (): JSX.Element => {
  return (
    <Box>
      <ResponsiveAppBar pages={pages} />
      <main
        style={{
          paddingTop: '70px',
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={12}>
            <Outlet />
          </Grid>
        </Grid>
      </main>
      <footer>
        <Container sx={{textAlign: 'center',marginTop: 30, paddingTop: 5, borderTop: '1px solid black'}}>
          <Typography
            variant="h4"
            component="p"
            sx={{
              mr: 2,
              textDecoration: 'none',
              fontVariant: 'small-caps',
              fontFamily: 'Bilbo',
              marginTop: '5px',
            }}
          >
            Never Stop Learning
          </Typography>
          <Stack direction={'row'} justifyContent='center' alignItems='center' spacing={5} sx={{marginTop:3}}>
            <Link
              href={'https://www.linkedin.com/in/frankmoley/'}
              target={'_blank'}
              rel={'noopener'}
            >
              <Box
                component="img"
                sx={{
                  border: '1px solid black',
                  borderRadius: '15%',
                  width: '32px',
                  display: 'block',
                  ml: 'auto',
                  mr: 'auto',
                }}
                src={LinkedIn}
              />
            </Link>
            <Link
              href={'https://www.github.com/fpmoles'}
              target={'_blank'}
              rel={'noopener'}
            >
              <Box
                component="img"
                sx={{
                  border: '1px solid black',
                  borderRadius: '15%',
                  width: '32px',
                  display: 'block',
                  ml: 'auto',
                  mr: 'auto',
                }}
                src={Github}
              />
            </Link>
            <Link
              href={'https://www.twitter.com/fpmoles'}
              target={'_blank'}
              rel={'noopener'}
            >
              <Box
                component="img"
                sx={{
                  border: '1px solid black',
                  borderRadius: '15%',
                  width: '32px',
                  display: 'block',
                  ml: 'auto',
                  mr: 'auto',
                }}
                src={Twitter}
              />
            </Link>
          </Stack>
          <Typography variant={'body2'} sx={{marginTop: 2}}>
            &copy; 2022 Frank P Moley III
          </Typography>
        </Container>
      </footer>
    </Box>
  );
};

export default MainLayout;
