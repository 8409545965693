import React, {useLayoutEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Button, Container, Paper, Typography} from '@mui/material';
import {Talk} from '../types/site';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const workshopTalks:Talk[] = [
  {
    title: 'Introduction to Spring',
    description: 'This 8 hour workshop is designed to introduce the Java developer to the Spring Framework. It starts with the basics of configuring an application for basic Spring Inversion of Control and walks through several of the pieces of the core project. This workshop provides both lecture and hands on exercises to enforce the basic knowledge.',
    materials: 'https://github.com/fpmoles/talks-spring-intro-workshop'
  }
];
const sessionTalks:Talk[] = [
  {
    title: '12 Factor Application Development',
    description: 'Today\'s modern systems have global reach, and as such public, private, and hybrid clouds are the place to deploy your applications. The issue with many of these solutions, however, is that many parts of application development that work on big iron servers are no longer in play. Limited if any file systems, distributed backing resources, and complex team locations and structures need a newer way of doing things. 12 factor applications, or cloud native applications attempt to bring agile development and cloud resources into coherence. This presentation will focus on the twelve distinct components, workflows, and techniques for cloud based application development. It will be language agnostic, and instead focus on the principles behind the actual development.',
    materials: 'https://github.com/fpmoles/talks-twelve-factor'
  },
  {
    title: 'DevSecOps: Building a Culture of Security',
    description: 'In today\'s world, we move a million miles an hour to deliver code as fast as possible with as few resources as possible. We have moved into agile teams that churn over backlog to get to production. We have merged development and operations to improve efficiencies in delivery. The next major step is integrating the ever changing world of Security into the pipeline to mitigate risks while delivering at a high throughput. This talk will discuss was to improve your team\'s culture around security and focus on ways to make it a first class citizen for your team instead of an afterthought.',
    materials: 'https://github.com/fpmoles/talks-devsecops'
  },
  {
    title: 'Spring: Under the Hood',
    description: 'The Spring Framework is by far the most popular java framework in use today, and for good reason. The IoC container, common enterprise abstractions, and open source strategy have allowed the framework to respond to business and user needs. As cloud computing has increased in scope, so has the offerings of Spring. Today’s Spring developer has many tools in his or her belt for building scalable application, but very few of these developers have spent time digging into how the framework itself operates. Knowledge of the core operations of the framework aids in troubleshooting issues, extending the framework to leverage business needs, finding more robust and efficient ways of solving complex problems, and ultimately submitting back to the framework. This talk looks at the Spring Bean lifecycle and what it takes to actually get the container up and running. We will look at the various ways of configuring the bean factory through its wrapper the Application Context. We will discuss the ways the framework leverages proxies to add behavior to your POJOs and opportunities you have to impact that behavior through lifecycle methods, lifecycle beans, or aspecting.',
    materials: 'https://github.com/fpmoles/talks-spring-under-the-hood'
  },
  {
    title: 'Understanding Java Cryptography',
    description: 'Cryptography is one of those areas in software development that invokes immediate reactions in engineers. You either absolutely love the complexities of the math involved and the nuances of the algorithms, or you prefer to just ignore this entire segment of software development. Cryptography may be a complex mathematical concept, but leveraging the crypto libraries isn\'t complex once you have seen them in action and understand the use cases. In this talk we will start our discussion with cryptography and first rule of crypto. We will then look at the most utilized aspects of the java crypto library and discuss proper use of the specific APIs. We will discuss use cases when you want to leverage a specific API, some of the options contained within, and some of the potential gotchas that could render your system less secure. By the time you leave this talk, you should have a basic understanding of the java crypto packages and basic uses cases.',
    materials: 'https://github.com/fpmoles/talks-java-crypto'
  },
  {
    title: 'Welcome to Cloud Native Spring',
    description: 'Spring Cloud provides a series of projects dedicated to making 12 factor or cloud native application development possible. Armed with basic knowledge of the Spring Framework, this talk will provide the audience some of the basics needed to build Cloud Native applications with the Spring Framework',
    materials: 'https://github.com/fpmoles/talks-welcome-to-cloud-native-spring'
  }
];
const Speaking = ():JSX.Element => {

  const [workshops, setWorkshops] = useState<Talk[]>([]);
  const [sessions, setSessions] = useState<Talk[]>([]);

  useLayoutEffect(()=> {
    setWorkshops(workshopTalks);
    setSessions(sessionTalks);
  });

  return(
    <Container>
      <p>Interested in having me speak at your user group or conference on these or any other topic, please reach out to me via Twitter: <a href={'https://twitter.com/fpmoles'}>@fpmoles</a></p>
      <Paper elevation={2}>
        <h2>Workshops</h2>
        {workshops.map((workshop, i)=>{
          return(
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={'workshopPanel' + i}>
                <Typography variant={'h6'}>{workshop.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{workshop.description}</Typography>
                {workshop.materials?<Button href={workshop.materials} target={'_blank'} rel={'noopener'}>Materials</Button>:<></>}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Paper>
      <Paper elevation={2}>
        <h2>Sessions</h2>
        {sessions.map((session, i)=>{
          return(
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={'sessionPanel' + i}>
                <Typography variant={'h6'}>{session.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{session.description}</Typography>
                {session.materials?<Button href={session.materials} target={'_blank'} rel={'noopener'}>Materials</Button>:<></>}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Paper>
    </Container>
  );
};

export default Speaking;