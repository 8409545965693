import React from 'react';
import { Box } from '@mui/system';
import ProfilePicture from '../assets/fpm_prof1.jpg';
import {Container} from '@mui/material';

const Home = (): JSX.Element => {
  return (
    <Box>
      <Box
        component="img"
        sx={{
          border: '1px solid black',
          borderRadius: '15%',
          maxWidth: '250px',
          display: 'block',
          ml: 'auto',
          mr: 'auto',
        }}
        src={ProfilePicture}
      />
      <Box sx={{ textAlign: 'center' }}>
        <h1>Frank P Moley III</h1>
        <h4>
          Cloud Native developer, architect, teacher, student. Focused on distributed systems and data, security,
          quality, and teaching.
        </h4>
        <p>Designer of systems</p>
        <p>Tamer of clouds</p>
        <p>Student of the world</p>

      </Box>
      <Container>

      </Container>
    </Box>
  );
};

export default Home;
