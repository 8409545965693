import React, { useState } from 'react';
import { Company } from '../../types/site';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button,
  Card,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Job(company: Company): JSX.Element {
  const [isActive] = useState<boolean>(company.dates.includes('current'));

  return (
    <Box>
      <Card sx={{ marginBottom: 2 }} elevation={2}>
        <Grid container rowSpacing={0.5} columnSpacing={2}>
          <Grid item xs={6} md={8}>
            <Typography variant={'h4'}>{company.name}</Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              >
                {company.dates}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} md={8}>
            <Button href={company.url} target={'_blank'} rel={'noopener'}>Company Website</Button>
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            {isActive ? <Chip sx={{ float: 'right' }} label={'Active'} size={'small'} color={'secondary'} /> : <></>}
          </Grid>
          <Grid item xs={12} md={12}>
            {company.roles.map((role, i) => {
              return (
                <Accordion key={i} defaultExpanded={i === 0 && isActive}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} id={'rolePanel' + i}>
                    <Typography variant={'h5'}>{role.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{role.description}</Typography>
                    <List>
                      {role.functions.map((item, fi) => {
                        return (
                          <ListItem disablePadding key={fi}>
                            <ListItemIcon>
                              <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText>{item}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })};
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default Job;
