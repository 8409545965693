import React from 'react';
import { Box } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';

const ErrorPage = (): JSX.Element => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <ReportIcon sx={{ fontSize: '7em' }} />
      <h1>Oops, Something went wrong.</h1>
      <h3>Either I broke navigation or you entered an invalid URL, either way nothing is here.</h3>
    </Box>
  );
};

export default ErrorPage;
