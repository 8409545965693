import { ThemeOptions } from '@mui/material';

export const LightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#78909c',
      light: '#a7c0cd',
      dark: '#4b636e',
      contrastText: '#000000',
    },
    secondary: {
      main: '#9c8478',
      light: '#cdb4a7',
      dark: '#6d574c',
      contrastText: '#000000',
    },
    background: {
      default: '#cfd8dc',
      paper: '#eceff1',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
    },
    error: {
      main: '#f44336',
    },
  },
  typography: {
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontSize: '4.4rem',
      fontWeight: 500,
    },
    fontFamily: 'Raleway',
  },
  shape: {
    borderRadius: 12,
  },
};
